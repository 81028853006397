import differenceInDays from 'date-fns/differenceInDays';

import { BoostOrigin } from './types';

import { BOOST_LISTINGS_MIN_ACCOUNT_AGE_IN_DAYS } from '@/constants/boostedListings';

export function shouldUserSeeBoostedListings(dateJoined?: string) {
  if (!dateJoined) {
    return false;
  }

  const daysSinceJoined = differenceInDays(new Date(), new Date(dateJoined));
  return Boolean(daysSinceJoined > BOOST_LISTINGS_MIN_ACCOUNT_AGE_IN_DAYS);
}

export function calculateBoostClickPercentageIncrement(
  organicClicks: number,
  boostedClicks: number
) {
  if (boostedClicks === 0) {
    return undefined;
  }
  if (organicClicks === 0 && boostedClicks > 0) {
    return 100;
  }
  return Math.round(
    ((organicClicks + boostedClicks) / organicClicks - 1) * 100
  );
}

export function formatBoostClickPercentage(
  organicClicks: number,
  boostedClicks: number
) {
  const percentage = calculateBoostClickPercentageIncrement(
    organicClicks,
    boostedClicks
  );
  if (!percentage) {
    return undefined;
  }
  if (percentage === 100) {
    return '100%';
  }
  return `+${percentage}%`;
}

export function getBoostOrigin(pathname = '') {
  if (pathname === '/sellinghub/boost/[status]') {
    return BoostOrigin.BoostedListings;
  }
  if (pathname === '/products/[slug]') {
    return BoostOrigin.ProductPage;
  }
  if (pathname === '/[username]') {
    return BoostOrigin.ShopPage;
  }
  if (pathname === '/sellinghub/selling/active/') {
    return BoostOrigin.ShopPage;
  }

  return undefined;
}
