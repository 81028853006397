import {
  CategoriesById,
  CategoriesByPath,
  CategoryFilters,
  CategorySizeMapResponse,
  SubcategoryFilters,
} from './types';

import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { CMSLocales, LegacyCMSCategoryPage } from '@/modules/cms/types';
import {
  CATEGORIES_BY_ID_ENDPOINT,
  CATEGORIES_BY_PATH_ENDPOINT,
  CATEGORY_PAGE_METADATA_ENDPOINT,
  CATEGORY_SIZE_MAP_ENDPOINT,
  CATEGORY_FILTERS,
} from '@/constants/endpoints';
import { depopMetaToHeaders } from '@/modules/search/api';
import { DepopRequestMeta } from '@/modules/search/types';

export function fetchCategoryPage(
  categoryId: string,
  locale: CMSLocales
): Promise<AxiosCompatibleResponse<LegacyCMSCategoryPage>> {
  return http.get(
    CATEGORY_PAGE_METADATA_ENDPOINT.replace(':categoryId', categoryId),
    {
      params: {
        locale,
      },
      cache: 'force-cache',
    }
  );
}

export function fetchCategoriesById(
  meta: DepopRequestMeta
): Promise<AxiosCompatibleResponse<CategoriesById>> {
  return http.get(CATEGORIES_BY_ID_ENDPOINT, {
    headers: depopMetaToHeaders(meta),
    cache: 'force-cache',
  });
}

export function fetchCategoryByPathData(): Promise<
  AxiosCompatibleResponse<CategoriesByPath>
> {
  return http.get(CATEGORIES_BY_PATH_ENDPOINT, { cache: 'force-cache' });
}

export function fetchCategoryFilters(): Promise<
  AxiosCompatibleResponse<{
    categoryFilters: CategoryFilters;
    subcategoryFilterDictionary: SubcategoryFilters;
  }>
> {
  return http.get(CATEGORY_FILTERS, {
    cache: 'force-cache',
  });
}

export function fetchCategorySizeMap(): Promise<
  AxiosCompatibleResponse<CategorySizeMapResponse>
> {
  return http.get(CATEGORY_SIZE_MAP_ENDPOINT, {
    cache: 'force-cache',
  });
}
