import { CurrencyCode } from '../currencies/constants';
import { PictureFormat } from '../product/types';

export enum BoostOrigin {
  BoostedListings = 'boosted_listings',
  ProductPage = 'product_page',
  ShopPage = 'shop_page',
}

export interface Meta {
  cursor: string;
  hasMore: boolean;
}

export interface ListingRow {
  id: number;
  price: {
    currencyName: CurrencyCode;
    priceAmount: string;
    discountedPriceAmount?: string;
  };
  description: string;
  pictures: PictureFormat[][];
  likeCount?: number;
  sizes: string[];
  category: string;
}

export interface BoostedListingRow extends ListingRow {
  dateBoostUpdated?: string;
  organicClicks?: number;
  boostedClicks?: number;
  organicImpressions?: number;
  boostedImpressions?: number;
}

export interface SoldBoostedListingRow extends BoostedListingRow {
  dateBoostCreated: string;
  dateSold: string;
}

export interface BoostedListings {
  products: BoostedListingRow[];
  meta: Meta;
}

export interface ListingRowWithBoostedStatus extends ListingRow {
  boosted: boolean;
  dateCreated: string;
  dateUpdated: string;
}

export enum BoostedListType {
  'All' = 'all',
  'Boosted' = 'boosted',
}

export interface BoostingStatistics {
  soldBoostedCount: number;
}

export interface ListingsWithPromotedStatus {
  products: ListingRowWithBoostedStatus[];
  meta: Meta;
}
