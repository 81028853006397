import { useQuery } from '@tanstack/react-query';

import { getBulkListingData } from '@/modules/bulkListing/api';
import { RQ_BULK_LISTING_ENABLED_KEY } from '@/modules/ReactQuery/cacheKeys';
import { BulkListingEnabledStatus } from '@/modules/user/types';

export function useBulkListingEnabled(
  userId?: number
): BulkListingEnabledStatus {
  const { data } = useQuery({
    queryFn: async () => getBulkListingData(),
    queryKey: [RQ_BULK_LISTING_ENABLED_KEY, userId],
    enabled: Boolean(userId),
  });

  return {
    hasAccess: data?.data?.hasAccess || false,
  };
}
