import { BULK_LISTING_ENABLED_ENDPOINT } from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { BulkListingEnabledStatus } from '@/modules/user/types';

export async function getBulkListingData(): Promise<
  AxiosCompatibleResponse<BulkListingEnabledStatus>
> {
  return http.get(BULK_LISTING_ENABLED_ENDPOINT, { withAuth: true });
}
