import { useQueryClient } from '@tanstack/react-query';

import { User } from './types';

import { shouldUserSeeBoostedListings } from '@/modules/boostedListings/helpers';
import { useBulkListingEnabled } from '@/modules/bulkListing/useBulkListing';
import { RQ_USER_DATA_KEY } from '@/modules/ReactQuery/cacheKeys';

export function isAuthenticated(user: User | null): user is User {
  return Boolean(user?.id);
}

export function useCurrentUser(): [boolean, User | null] {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<User>([RQ_USER_DATA_KEY]) || null;
  const authenticated = Boolean(user?.id);

  const { hasAccess } = useBulkListingEnabled(user?.id);
  const hasBoostedListingsAccess = shouldUserSeeBoostedListings(
    user?.dateJoined
  );

  return [
    authenticated,
    user?.id
      ? { ...user, hasBoostedListingsAccess, hasBulkListingAccess: hasAccess }
      : user,
  ];
}
