import { AxiosCompatibleResponse } from '../http/types';

import {
  BrandByIdResponse,
  BrandsBatchBySlugResponse,
  BrandsBatchIdsResponse,
  OrderedBrandsResponse,
} from './types';

import http from '@/modules/http';
import {
  BRANDS_BY_ID_ENDPOINT,
  BRANDS_BATCH_BY_ID_ENDPOINT,
  ORDERED_BRANDS_ENDPOINT,
  BRANDS_BATCH_BY_SLUG_ENDPOINT,
} from '@/constants/endpoints';
import { depopMetaToHeaders } from '@/modules/search/api';
import { DepopRequestMeta } from '@/modules/search/types';

export async function fetchOrderedBrands(): Promise<
  AxiosCompatibleResponse<OrderedBrandsResponse>
> {
  return http.get(ORDERED_BRANDS_ENDPOINT, { cache: 'force-cache' });
}

export async function fetchOrderedBrandsByLetter(startsWith: string) {
  const { data } = await http.get<OrderedBrandsResponse>(
    ORDERED_BRANDS_ENDPOINT + `?startsWith=${startsWith}`,
    {
      cache: 'force-cache',
    }
  );

  return data;
}

export async function fetchBrandsById(): Promise<
  AxiosCompatibleResponse<BrandByIdResponse>
> {
  return http.get(BRANDS_BY_ID_ENDPOINT, {
    cache: 'force-cache',
  });
}

export async function fetchBrandsBatchIds({
  brandIds,
  meta,
}: {
  brandIds: string[] | number[];
  meta: DepopRequestMeta;
}): Promise<AxiosCompatibleResponse<BrandsBatchIdsResponse>> {
  return http.get(
    BRANDS_BATCH_BY_ID_ENDPOINT.replace(':brandIds', brandIds.join(',')),
    {
      withDeviceId: true,
      cache: 'force-cache',
      headers: depopMetaToHeaders(meta),
    }
  );
}

export async function fetchBrandsBatchBySlugs({
  brandSlugs,
}: {
  brandSlugs: string[];
}): Promise<AxiosCompatibleResponse<BrandsBatchBySlugResponse>> {
  return http.get(
    BRANDS_BATCH_BY_SLUG_ENDPOINT.replace(':brandSlugs', brandSlugs.join(','))
  );
}
